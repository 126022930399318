/*  PLATAFORMA  */
/* MIRA MI CARTA ####################################################*/
.fondoLogin_mmc{
    background: url(/assets/img/fondo_mmc.jpg) no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.cabeceraLogin_mmc{
  background-color: rgba(217, 33, 45, 0.78) !important;
  background-image: none !important;
}
.boxShadow_mmc{
  box-shadow: 0px 0px 3px 3px #ae111b !important;
}
.button_mmc{
  background-color: rgba(217, 33, 45, 0.78) !important;
  border-color: #ae111b !important;
}
/* MIRA MI CATALOGO ####################################################*/
.fondoLogin_cat{
    /*background: url(/assets/img/fondo_cat.jpg) no-repeat center center fixed;
    background-size: cover;*/
    background: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.cabeceraLogin_cat{
  background-color: rgba(54, 187, 209, 0.78) !important;
  background-image: none !important;
}
.boxShadow_cat{
  box-shadow: 0px 0px 3px 3px #36bbd1 !important;
}
.button_cat{
  background-color: rgba(54, 187, 209, 0.78) !important;
  border-color: #36bbd1 !important;
  color: white !important;
}


/* GENERICO */
.img100{
  width: 100% !important;
  height: auto !important;
}

.img125{
  width: 125% !important;
  height: auto !important;
}

.img150{
  width: 150% !important;
  height: auto !important;
}

.img175{
  width: 175% !important;
  height: auto !important;
}

.img200{
  width: 200% !important;
  height: auto !important;
}

#root{
  background: none !important;
}

.btn-icon-ru{
    padding: 0rem 2rem 0rem 2rem !important;
    min-height: 47px;
    min-width: 108px;
}
.btn-icon-ru i{
  font-size: 30px;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  border-radius: 10px;
  background-color: #F5F5F5;
  opacity: .5;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
  opacity: .5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
  background-color: #555;
  opacity: .5;
}

.btn-search-ru{
  border-radius: 0.75rem;
  padding: 7px;
}

.textShadow{
  text-shadow: 1px 1px #ececec;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}

.iconCategory{
    font-size: 48px;
}

.talign-center{
  text-align: center;
}

.padding-top-75rem{
  padding-top: 0.75rem;
}

.padding-top-10{
  padding-top: 10px;
}

.padding-top-15{
  padding-top: 15px !important;
}
.padding-top-5{
  padding-top: 5px !important;
}

.margin-top-15{
  margin-top: 15px !important;
}

#root{
  background: url(/assets/img/claro.jpg) no-repeat center center fixed;
  /*min-height: 580px;*/
}

.width33{
  width: 30% !important;
}

.width100{
  width: 100% !important;
}

.width90{
  width: 90% !important;
}

.width50{
  width: 50% !important;
}

.width70{
  width: 70% !important;
}

.noBackground{
  background: 0 0!important;
  background-color: none !important
}

.divMenuModal{
  padding:15px;
}

.noBorderTop{
  border-top:0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.divMenuModal .card .row{
      border-top: 1px solid rgba(0,0,0,0.125) !important;
      padding-top:5px;
}

.divMenuModal .card img{
  max-width: 100% !important;
  max-height: auto !important;
}

.divMenuModal .card{
      padding:15px;
      margin-bottom:10px;
      border: 1px solid rgba(0,0,0,0.125) !important;
}

.padding30{
  padding: 30px !important;
}

.badge2nd{
    top: 43px !important;
}

.badge.badge-bottom-right {
    bottom: 8px;
    right: -7px;
}

.badge.badge-bottom-left {
    bottom: 8px;
    left: -7px;
}

.NavItem-link{
  padding: 8px 0;
  margin-bottom: 10px;
}

.modalImg{
  width:100% !important;
  height:100% !important;
  min-height: 80px !important;
}

.alergIcon{
  height:40px;
  width: auto;
}

.modalIcons{
  padding: 26px;
}

.modal-body{
  padding: 0px !important;
}

.bodyFontSize{
  font-size: 1.1rem !important;
}

.heightCalc160{
  height: calc(100% - 160px);
}

.heightCalc180{
  height: calc(100% - 185px);
}

.modal-title{
  font-size: 1.4rem !important;
}

.cardShadow{
  box-shadow: 0px 0px 10px 3px #ccc !important;
}

.namePlace{
  padding-bottom: 5px;
margin-bottom: 0px !important;
}

.fullCategory{
  height: 139px;
}

.cardTable{
  display: table !important;
    width: 157px;
}

.textvaling{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.categoryImg{
  max-width: auto;
  max-height: 80px;
  display: block;
  margin: auto;
  object-fit: cover;
}

.categoryIcon{
  height: 80px;
  width: 80px;
  display: block;
  margin: auto;
}

.react-select__single-value {
    padding-top: 0px !important;
}

.selectBorder{
  width: 100%;
  border: 1px solid black;
}

.heightAuto{
  height: auto !important;
}

.height50{
  height: 50px !important;
}

.height60{
  height: 60px !important;
}

.height70{
  height: 70px !important;
}

.placeImg{
  max-height:40px !important;
  width:auto !important;
}

.card_order_history{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.group-product{
  padding: 0.25rem !important;
  margin-top: .25rem !important;
}

.footer-content{
    margin-left: 0px !important;
}

.page-footer{
    position: sticky;
    bottom: 0;
    z-index: 20;
}

.imageSidebar{
  max-height: 30px !important;
    width: auto !important;
}

.noMobile{
  height:640px !important;
  width: 360px !important;
}

.select-transparent, .select-transparent > div,
 .select-transparent > div > div,
 .select-transparent .react-select__single-value,
 .select-transparent path{
    background: transparent !important;
    border: none !important;
    color: white !important;
}

.react-select-top .react-select__menu{
  /*margin-top: 0px !important;
  padding-left: 100px !important;*/
}

.select-transparent .react-select__option--is-selected {
    color: #fff !important;
}

.select-solid .react-select__option--is-selected {
    color: #fff !important;
}

.react-select__menu{
  margin-bottom: 0px !important;
  z-index: 1200 !important;
}

.modalHeightCalc{
    max-height: calc(100vh - 250px);
    overflow: auto;
}



main{
  min-height: calc(100vh - 123px) !important;
}

.react-select__menu{
  margin-bottom: 0px !important;
}

.footerBottomSpan{
  padding: 12px;
  font-size: 20px;
  color: white;
  border-right: 1px solid #fff;
  text-align: center;
}

.loader{
  display: inline-block;
  left: calc(50% - 75px);
  top: calc(50% - 170px);
  position: fixed;
  z-index: 1;
}

.botonQty{
  font-size:20px !important;
  height: 45px;
  width: 40px;
  margin: 0 auto !important;
  padding: 0px !important;
  border-radius: 0 !important;
}

.borderPrimary{
  border-top: 2px solid var(--theme-color-1);
}

.modalPago {
  overflow: hidden;
  height:97%;
}

.centrado{
  margin: 0 auto !important;
}

.modalPago .modal-content {
  height:100%;
}

iframe{
  border: none;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #fff !important;
    opacity: 1;
}
